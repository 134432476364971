<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="text-center">
        <h1>
          Wijs
        </h1>
        <v-divider></v-divider>
      </v-col>

      <v-col cols="12">
        Wijs vormgeven van jouw ambitie en uitdaging!
      </v-col>

      <v-col cols="12" class="mt-4">
        <p class="font-weight-regular">
          Het vormgeven van ontwikkeling kan zowel op individueel als op team niveau.
        </p>

        <p class="font-weight-regular">
          Hier word je geholpen met jouw individueel ontwikkel plan, het I.O.P.
        </p>

        <p class="font-weight-regular">
          Wanneer je met jouw team, in een Humanwise ontwikkel programma, ook werkt aan een team ontwikkel plan (T.O.P), dan kun je hier het plan
          vinden.
        </p>
      </v-col>



      <v-col cols="12" class="text-center">
        <h1>
          Plannen
        </h1>
        <v-divider></v-divider>
      </v-col>
      <v-col cols="12" class="mt-4">
        <v-row no-gutters>
          <v-col cols="6" class="px-6 cursor-pointer" @click="openIOP">
            <div class="d-flex align-start justify-center">
              <img src="../../assets/own/IOP-cirkel.svg" height="80px" alt="Individueel Ontwikkel Plan">
            </div>
            <div class="text-blue d-flex align-end justify-center text-center">
              Individueel Ontwikkel Plan
            </div>
          </v-col>

          <v-col cols="6" class="px-6 cursor-pointer" @click="openTOP">
            <div class="d-flex align-start justify-center">
              <img src="../../assets/own/TOP-cirkel.svg" height="80px" alt="Team Ontwikkel Plan">
            </div>
            <div class="text-blue d-flex align-end justify-center text-center">
              Team Ontwikkel Plan
            </div>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" class="text-center">
        <h1>
          Feedback
        </h1>
        <v-divider></v-divider>
      </v-col>
      <v-col cols="12" class="mt-4" v-if="getFeedbackError">
        Er ging iets mis bij het ophalen van de feedback. Probeer het later opnieuw.
      </v-col>

      <v-col cols="12" class="mt-4" v-else-if="!hasFeedback()">
        Je hebt nog geen feedback uitnodiging verstuurd.
      </v-col>

      <v-col cols="12" class="mt-4" v-else>
        <v-row>
          <v-col cols="12" lg="6">
            <v-row>
              <v-col cols="12">
                <h3>
                  Feedback op je I.O.P.
                </h3>
              </v-col>
              <v-col cols="12" v-if="feedback.type_1 === null">
                Nog geen feedback uitnodigingen verstuurd.
              </v-col>
              <v-col cols="12" v-else v-for="(fb, index) in feedback.type_1" :key="index" @click="visitFeedback(fb)" class="cursor-pointer">
                <v-row>
                  <v-col cols="6">
                    {{ fb.firstname }} {{ fb.lastname }}
                  </v-col>
                  <v-col cols="6">
                    <v-icon color="green" v-if="fb.done">fa fa-check-circle</v-icon>
                    <v-icon color="red" v-if="!fb.done">fa fa-times-circle</v-icon>
                    {{ fb.done ? "Feedback ingevuld" : "Uitnodiging verzonden" }}
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" lg="6">
            <v-row>
              <v-col cols="12">
                <h3>
                  Feedback op je voortgang en persoonlijke ontwikkeling
                </h3>
              </v-col>
              <v-col cols="12" v-if="feedback.type_2 === null">
                Nog geen feedback uitnodigingen verstuurd.
              </v-col>
              <v-col cols="12" v-else v-for="(fb, index) in feedback.type_2" :key="index" @click="visitFeedback(fb)" class="cursor-pointer">
                <v-row>
                  <v-col cols="6">
                    {{ fb.firstname }} {{ fb.lastname }}
                  </v-col>
                  <v-col cols="6">
                    <v-icon color="green" v-if="fb.done">fa fa-check-circle</v-icon>
                    <v-icon color="red" v-if="!fb.done">fa fa-times-circle</v-icon>
                    {{ fb.done ? "Feedback ingevuld" : "Uitnodiging verzonden" }}
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>

        </v-row>
      </v-col>
    </v-row>

    <v-dialog
        transition="dialog-bottom-transition"
        max-width="600"
        v-model="showDialog"
        style="z-index: 10000"
    >

      <template v-slot:default="dialog">
        <v-card>
          <v-toolbar
              color="accent"
              dark
          >Feedback {{ selectedFeedback.type === 1 ? "op mijn I.O.P." : "op mijn voortgang en ontwikkeling" }}
          </v-toolbar>
          <v-card-text v-if="selectedFeedback.done" class="pt-2">
            <v-row>
              <v-col cols="12">
                <h3>Ingevuld door {{ selectedFeedback.firstname }} {{ selectedFeedback.lastname }}</h3>
              </v-col>
            </v-row>
            <v-row v-if="selectedFeedback.row_1">
              <v-col cols="12" style="background-color: #009EE0">
                {{ selectedFeedback.row_1 }}
              </v-col>
              <v-col cols="12">
                Feedback: {{ selectedFeedback.feedback_row_1 }}
              </v-col>
            </v-row>
            <v-row v-if="selectedFeedback.row_2">
              <v-col cols="12" style="background-color: #009EE0">
                {{ selectedFeedback.row_2 }}
              </v-col>
              <v-col cols="12">
                Feedback: {{ selectedFeedback.feedback_row_2 }}
              </v-col>
            </v-row>
            <v-row v-if="selectedFeedback.row_3">
              <v-col cols="12" style="background-color: #009EE0">
                {{ selectedFeedback.row_3 }}
              </v-col>
              <v-col cols="12">
                Feedback: {{ selectedFeedback.feedback_row_3 }}
              </v-col>
            </v-row>
            <v-row v-if="selectedFeedback.row_4">
              <v-col cols="12" style="background-color: #009EE0">
                {{ selectedFeedback.row_4 }}
              </v-col>
              <v-col cols="12">
                Feedback: {{ selectedFeedback.feedback_row_4 }}
              </v-col>
            </v-row>
            <v-row v-if="selectedFeedback.row_5">
              <v-col cols="12" style="background-color: #009EE0">
                {{ selectedFeedback.row_5 }}
              </v-col>
              <v-col cols="12">
                Feedback: {{ selectedFeedback.feedback_row_5 }}
              </v-col>
            </v-row>
            <v-row v-if="selectedFeedback.row_6">
              <v-col cols="12" style="background-color: #009EE0">
                {{ selectedFeedback.row_6 }}
              </v-col>
              <v-col cols="12">
                Feedback: {{ selectedFeedback.feedback_row_6 }}
              </v-col>
            </v-row>
            <v-row v-if="selectedFeedback.row_7">
              <v-col cols="12" style="background-color: #009EE0">
                {{ selectedFeedback.row_7 }}
              </v-col>
              <v-col cols="12">
                Feedback: {{ selectedFeedback.feedback_row_7 }}
              </v-col>
            </v-row>
            <v-row v-if="selectedFeedback.row_8">
              <v-col cols="12" style="background-color: #009EE0">
                {{ selectedFeedback.row_8 }}
              </v-col>
              <v-col cols="12">
                Feedback: {{ selectedFeedback.feedback_row_8 }}
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text v-else class="pt-2">
            <h3>{{ selectedFeedback.firstname }} {{ selectedFeedback.lastname }} heeft nog geen feedback ingevuld.</h3>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn
                text
                @click="dialog.value = false"
            >Sluiten
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </v-container>
</template>

<script>

import apiClient from "@/services/apiClient";

export default {
  name: "dashboardHome",
  data() {
    return {
      feedback: {
        type_1: null,
        type_2: null,
      },
      getFeedbackError: false,
      showDialog: false,
      selectedFeedback: null,
    }
  },
  mounted() {
    apiClient.get("iop/feedback").then(response => {
      if (response.data.has_type_1) {
        this.feedback.type_1 = response.data.type_1;
      } else {
        this.feedback.type_1 = null;
      }

      if (response.data.has_type_2) {
        this.feedback.type_2 = response.data.type_2;
      } else {
        this.feedback.type_2 = null;
      }

      this.getFeedbackError = false;
    }).catch(error => {
      this.getFeedbackError = true;
    });
  },
  methods: {
    hasFeedback() {
      return this.feedback.type_1 !== null || this.feedback.type_2 !== null;
    },
    visitFeedback(feedback) {
      this.selectedFeedback = feedback;
      this.showDialog = true;
    },
    openIOP() {
      this.$router.push({name: "dashboardWiseIOPBase"})
    },
    openTOP() {
      this.$router.push({name: "dashboardWiseTOPBase"})
    },
  }
};
</script>
